<template>
  <div :style="{ width: width, margin: 'auto', 'text-align': 'left' }">
    <b-row class="align-items-center">
      <b-col cols="12" md="4">
        <b-form-group label="ประเทศ">
          <b-form-select
            :disabled="disabled"
            v-model="selectedCountry"
            :options="countryItems"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="8">
        <b-form-group
          label="หมายเลขโทรศัพท์มือถือ"
          :state="state"
          :validated="isValidated"
        >
          <b-form-input
            ref="inputMobileNo"
            :placeholder="placeholder"
            :disabled="disabled"
            autofocus
            trim
            v-model="mobileNo"
            type="tel"
            maxlength="16"
            @change="formatToE164"
            :state="state"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  parsePhoneNumber,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
export default {
  name: "mobile-no-input",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    selectedCountry: "TH",
    mobileNo: null,
    // countryItems: [{ value: "TH", text: "ไทย +66" }],
    phoneNumber: null,
    state: null,
    isValidated: false,
  }),
  computed: {
    countryItems() {
      let items = [];
      items = getCountries().map((x) => {
        return {
          value: x,
          text: `${x} (+${getCountryCallingCode(x)})`,
        };
      });
      return items;
    },
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.inputMobileNo.focus();
        }, 500);
      });
    },
    formatToE164() {
      try {
        const phoneNumber = parsePhoneNumber(
          this.mobileNo,
          this.selectedCountry
        );
        this.mobileNo = phoneNumber.format("INTERNATIONAL"); //phoneNumber.nationalNumber;
        this.phoneNumber = phoneNumber.format("E.164");
        this.state = true;
        this.isValidated = true;
      } catch (error) {
        console.log(error);
        this.mobileNo = "";
        this.phoneNumber = null;
        this.state = false;
        this.isValidated = false;
      }

      this.$emit("change", this.phoneNumber);
    },
    validate() {
      try {
        const phoneNumber = parsePhoneNumber(
          this.mobileNo,
          this.selectedCountry
        );
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>