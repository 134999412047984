<template>
  <b-modal
    id="mobileSetupDialog"
    title="การตั้งค่าหมายเลขโทรศัพท์มือถือ"
    centered
    v-model="dialog"
    @cancel="dismiss"
    hide-header-close
    no-close-on-backdrop
  >
    <div class="bg-white">
      <div>
        <p class="">กรุณาระบุหมายเลขโทรศัพท์มือถือ</p>
        <div class="">
          <mobile-no-input
            ref="mobileInputSetup"
            @change="onMobileChange"
            :disabled="isCountdown"
            placeholder="เช่น 080XXXXXXX"
          ></mobile-no-input>
        </div>
        <hr />
        <div class="text-center mt-4">
          <div>
            <div class="mt-2 mb-2">
              กรุณาป้อนรหัส 6 หลัก ที่ส่งไปยังโทรศัพท์มือถือของท่าน
            </div>
            <div class="mt-2 mb-3">
              รหัสอ้างอิง: <strong class="text-info">{{ refNo }}</strong>
            </div>
            <input-series
              ref="totpToken"
              width="360px"
              series-id="mobile-totp-token"
              :autoFocus="false"
              @filled="onTokenFilled"
            ></input-series>
            <div class="mt-4 mb-4">
              <div class="m-auto" style="width: 360px">
                <template v-if="isCountdown">
                  <vue-countdown
                    ref="countdown"
                    :time="timeValue"
                    :auto-start="false"
                    @end="resetCountdown"
                    @abort="resetCountdown"
                    v-slot="{ totalSeconds }"
                  >
                    <div>
                      เหลือเวลา
                      <strong>
                        {{
                          totalSeconds == 0 ? timeValue / 1000 : totalSeconds
                        }}</strong
                      >
                      วินาที
                    </div>
                    <div class="mt-2">
                      <b-progress
                        :variant="progressAutoColor(totalSeconds)"
                        :value="
                          totalSeconds == 0 ? timeValue / 1000 : totalSeconds
                        "
                        :max="timeValue / 1000"
                      ></b-progress>
                    </div>
                  </vue-countdown>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <b-button variant="light" @click="cancel()" :disabled="overlay">
            ยกเลิก
          </b-button>
        </div>
        <div class="flex-shrink-1">
          <b-button
            @click="reqOTP"
            variant="primary"
            :disabled="mobileNo == '' || mobileNo == null || isCountdown"
            >ขอรหัส OTP</b-button
          >
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import Vue from "vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import InputSeries from "../input-series.vue";
import mobileNoInput from "../mobileNoInput.vue";
import accountService from "../../services/accountService";

export default {
  name: "setup-mobile",
  components: {
    InputSeries,
    mobileNoInput,
    VueCountdown,
  },
  data: () => ({
    dialog: false,
    step: 1,
    stepper: null,
    showQRLinkApp: false,
    shareSecret: "",
    showShareSecret: false,
    isTokenCorrected: false,
    overlay: false,
    mobileNo: null,
    isCountdown: false,
    timeValue: 60 * 1000,
    refNo: "",
  }),
  watch: {
    // dialog(val) {
    //   if (val == false) {
    //     this.checkClose();
    //   }
    // },
  },
  beforeDestroy() {
    this.refNo = "";
    this.isCountdown = false;
  },
  mounted() {},
  methods: {
    open(step = 1) {
      this.isCountdown = false;
      this.refNo = "";
      this.dialog = true;

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            focus: this.focusInput,
          });
        });
      });
    },
    focusInput() {
      this.$refs.mobileInputSetup.focus();
    },
    dismiss(val = false) {
      this.dialog = false;
      this.overlay = false;
      this.step = 1;
      this.$emit("dismiss", val);
    },
    reqOTP() {
      if (this.$refs.mobileInputSetup.validate() == false) {
        return;
      }

      this.overlay = true;
      this.resetCountdown();
      this.refNo = "";
      accountService.mobile
        .reqTOTP(this.mobileNo, true)
        .then((result) => {
          // console.log(result);
          // this.timeValue = (60 + result.left) * 1000; // in case plus remaining time
          this.timeValue = 60 * 1000;
          this.refNo = result.refNo;
          // console.log(this.timeValue, this.timeValue / 1000);
          this.isCountdown = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.countdown.start();
            });
          });
          this.$refs.totpToken.focusFirst();
        })
        .catch((err) => {
          // do nothing
          this.isCountdown = false;
          this.refNo = "";
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.mobile
        .verifyTOTP(token, true)
        .then((result) => {
          if (result == true) {
            // do something
            this.dismiss(true);
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$refs.totpToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    checkClose() {
      if (this.step == 3 || this.step == 2) {
        // because step 2 generate new share secret and verified = false
        this.$emit("statusChange");
      }
      // other step do nothimng
    },
    onMobileChange(val) {
      this.mobileNo = val;
      this.refNo = "";
      // if(this.mobileNo != "" && this.mobileNo != null){
      //   this.$refs.totpToken.focusFirst();
      // }
    },
    resetCountdown() {
      this.isCountdown = false;
    },
    getSeconds(props) {},
    progressAutoColor(val) {
      if (val > 40) {
        return "success";
      } else if (val > 20) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
};
</script>