<template>
  <div>
    <section class="section position-relative">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <accountMenu />
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="title">ตั้งค่าความปลอดภัย</h3>
            </div>

            <p class="border-bottom mt-2"></p>

            <template v-if="config != null">
              <v-row>
                <template v-if="show2FAAuth">
                  <v-col cols="12">
                    <div class="title mb-2 font-weight-bold">
                      เปิดใช้งาน Two Factor Authentication
                    </div>
                    <p>
                      เมื่อเปิดใช้งานโดยการเลือกอย่างใดอย่างหนึ่ง ระบบจะทำการส่ง
                      One Time Password ไปช่องทางที่คุณเลือก
                    </p>
                    <div class="d-inline-block" style="min-width: 360px">
                      <b-form-select
                        v-model="enabled2FA"
                        :options="twoFAoptions"
                        @change="on2FaChange"
                        :disabled="overlay"
                      ></b-form-select>
                    </div>
                    <div class="d-inline-block ml-4">
                      <b-button
                        variant="primary"
                        :disabled="!tfaChanged || overlay"
                        @click="updateTwoFactorAuth"
                      >
                        อัพเดท
                      </b-button>
                    </div>
                    <div class="mt-2">
                      <span class="text-danger"
                        >* ระบบล็อกอินยังไม่รองรับส่วนนี้</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                </template>
                <v-col cols="12" md="6" lg="6">
                  <div class="title font-weight-bold">E-Mail</div>
                  <div class="mt-4">
                    <template v-if="config.mail.verified === true">
                      <b-alert
                        variant="light"
                        show
                        class="pt-3"
                        style="min-height: 200px"
                      >
                        <div class="pa-3">
                          <strong class="text-success">
                            <i class="uil uil-check"></i>
                            ตรวจสอบแล้ว</strong
                          >
                          <hr />
                          <div class="pb-2">
                            <h6>อีเมลของคุณ: {{ config.mail.email }}</h6>
                          </div>
                          <p>
                            คุณได้ตรวจสอบและยืนยันอีเมลสำหรับรับ One Time
                            Password (OTP) เสร็จเรียบร้อยแล้ว
                          </p>
                        </div>
                      </b-alert>
                    </template>
                    <template v-else>
                      <!-- <div class="mb-4">
                        <p><em>Authenticator App คืออะไร</em></p>
                      </div> -->
                      <div
                        class="alert alert-light p-3"
                        role="alert"
                        style="min-height: 200px"
                      >
                        <strong class="text-warning">
                          <i class="uil uil-shield-slash"></i>
                          ยังไม่ยืนยัน</strong
                        >
                        <hr />
                        คุณยังไม่ได้ทำการยืนยันอีเมลของท่าน

                        <div class="mt-4">
                          <a
                            class="btn btn-primary"
                            @click="openVerifyEmailDialog"
                          >
                            เริ่มทำการยืนยัน
                          </a>
                          <div class="mt-2">
                            <span class="text-danger"
                              >* กรุณายืนยันอีเมลก่อนเริ่มตั้งค่าอื่นๆ</span
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="title font-weight-bold">Authenticator App</div>
                  <div class="mt-4">
                    <template
                      v-if="config.authenticator.verified === true"
                      style="min-height: 200px"
                    >
                      <b-alert variant="light" show class="pt-3">
                        <div class="pa-3">
                          <strong class="text-success">
                            <i class="uil uil-check"></i>
                            ตั้งค่าสำเร็จเรียบร้อยแล้ว</strong
                          >
                          <hr />
                          <p>
                            คุณได้ตั้งค่า Authenticator App เสร็จเรียบร้อยแล้ว
                            คุณสามารถตั้งค่าใหม่ได้อีกครั้ง โดยกดปุ่มด้านล่าง
                            และทำตามขั้นตอน
                          </p>

                          <div class="mt-4 mb-2">
                            <b-button
                              variant="outline-primary"
                              @click="deleteAuthenticator"
                            >
                              ลบการตั้งค่า
                            </b-button>
                          </div>
                        </div>
                      </b-alert>
                    </template>
                    <template v-else>
                      <!-- <div class="mb-4">
                        <p><em>Authenticator App คืออะไร</em></p>
                      </div> -->
                      <div
                        class="alert alert-light p-3"
                        role="alert"
                        style="min-height: 200px"
                      >
                        <strong class="text-warning">
                          <i class="uil uil-shield-slash"></i>
                          ยังไม่พบการตั้งค่า</strong
                        >
                        <hr />
                        <p>คุณยังไม่ได้เปิดใช้งาน กรุณาตั้งค่าตามขั้นตอน</p>

                        <div class="mt-4">
                          <b-button
                            variant="primary"
                            @click="setupAuthenticator"
                            :disabled="!isMailVerified"
                          >
                            เริ่มการตั้งค่า
                          </b-button>
                        </div>
                      </div>
                    </template>
                  </div>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <div class="title font-weight-bold">Mobile SMS</div>
                  <div class="mt-4">
                    <template v-if="config.mobileOtp.verified === true">
                      <b-alert
                        variant="light"
                        show
                        class="pt-3"
                        style="min-height: 200px"
                      >
                        <div class="pa-3">
                          <strong class="text-success">
                            <i class="uil uil-check"></i>
                            ตั้งค่าสำเร็จเรียบร้อยแล้ว</strong
                          >
                          <hr />
                          <div class="pb-2">
                            <h6>
                              หมายเลขโทรศัพท์ 4 หลักสุดท้าย:
                              {{ config.mobileOtp.last_digits }}
                            </h6>
                          </div>
                          <p>
                            คุณได้ตั้งค่าและยืนยันหมายเลขโทรศัพท์สำหรับรับ One
                            Time Password (OTP) เสร็จเรียบร้อยแล้ว
                          </p>

                          <div class="mt-4 mb-2">
                            <b-button
                              variant="outline-primary"
                              @click="changeMobileNo"
                              >เปลี่ยนหมายเลขโทรศัพท์</b-button
                            >
                          </div>
                        </div>
                      </b-alert>
                    </template>
                    <template v-else>
                      <!-- <div class="mb-4">
                        <p><em>Authenticator App คืออะไร</em></p>
                      </div> -->
                      <div
                        class="alert alert-light p-3"
                        role="alert"
                        style="min-height: 200px"
                      >
                        <strong class="text-warning">
                          <i class="uil uil-shield-slash"></i>
                          ยังไม่พบการตั้งค่า</strong
                        >
                        <hr />
                        คุณยังไม่ได้ระบุหมายเลขโทรศัพท์มือถือและทำการยืนยัน

                        <div class="mt-4">
                          <b-button
                            variant="primary"
                            @click="$refs.mobileSetupDialog.open()"
                            :disabled="!isMailVerified"
                          >
                            เริ่มการตั้งค่า
                          </b-button>
                        </div>
                      </div>
                    </template>
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="6"> </v-col>
              </v-row>
            </template>
          </div>
        </div>
      </div>

      <auth-setup-dialog ref="authSetupDialog" @dismiss="onAuthDismiss" />

      <remove-authenticator
        ref="removeAuthDialog"
        :config="config"
        @result="onAuthRemoveResult"
      />

      <verify-mail ref="mailVerifyDialog" @result="onMailVerifyResult" />

      <verify-old-phone-number
        ref="oldMobileNoVerifyDialog"
        @result="onOldPhoneNumberVerifyResult"
      />

      <mobile-setup-dialog
        ref="mobileSetupDialog"
        @dismiss="onMobileSetupDismiss"
      />

      <b-overlay :show="overlay" no-wrap></b-overlay>
    </section>
  </div>
</template>

<script>
import Vue from "vue";

import accountMenu from "../menu.vue";
import authenticatorSetupDialog from "../../../components/dialogs/authenticator.vue";
import removeAuthenticator from "../../../components/dialogs/dialogRemoveAuthenticator.vue";
import verifyMail from "../../../components/dialogs/verifyMail.vue";
import verifyOldPhoneNumber from "../../../components/dialogs/verifyOldPhoneNumber.vue";
import mobileSetupDialog from "../../../components/dialogs/setup-mobile.vue";
import accountService, {
  twoFactorAuthenOptions,
} from "../../../services/accountService";
import { EventBus } from "../../../event-bus";
export default {
  data: () => ({
    show2FAAuth: true,
    overlay: false,
    initialized: false,
    config: null,
    enabled2FA: null,
    tfaChanged: false,
  }),
  components: {
    accountMenu,
    removeAuthenticator,
    verifyMail,
    verifyOldPhoneNumber,
    mobileSetupDialog,
    authSetupDialog: authenticatorSetupDialog,
  },
  computed: {
    twoFAoptions() {
      let items = twoFactorAuthenOptions.filter((x) => x.value == null);
      if (this.config != null) {
        if (this.config.mail.verified) {
          items.push(twoFactorAuthenOptions.find((x) => x.value == "mail"));
        }
        if (this.config.mobileOtp.verified) {
          items.push(twoFactorAuthenOptions.find((x) => x.value == "sms-otp"));
        }
        if (this.config.authenticator.verified) {
          items.push(
            twoFactorAuthenOptions.find((x) => x.value == "authenticator")
          );
        }
      }

      return items;
    },
    isEnable2FA() {
      if (this.config.tfa != null && this.config.tfa != "") {
        return true;
      }

      return false;
    },
    isMailVerified() {
      if (this.config.mail != null) {
        return this.config.mail.verified;
      }

      return false;
    },
    isAuthenticatorVerified() {
      if (this.config.authenticator != null) {
        return this.config.authenticator.verified;
      }
      return false;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.overlay = true;
      accountService
        .getSecurityConfig()
        .then((cfg) => {
          this.config = cfg;
          this.enabled2FA = cfg.tfa;
        })
        .catch((err) => {
          // alert error
        })
        .finally(() => {
          this.overlay = false;
          this.initialized = true;
        });
    },
    on2FaChange(val) {
      console.log(val, this.config.tfa);
      if (this.config != null) {
        if (val != this.config.tfa) {
          this.tfaChanged = true;
        } else {
          this.tfaChanged = false;
        }
      } else {
        this.tfaChanged = false;
      }
    },
    updateTwoFactorAuth() {
      this.overlay = true;
      accountService
        .updateTwoFactorsAuth(this.enabled2FA)
        .then((result) => {
          this.tfaChanged = false;
          this.init();
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    //#region email
    openVerifyEmailDialog() {
      this.$refs.mailVerifyDialog.open();
    },
    onMailVerifyResult(val) {
      this.$refs.mailVerifyDialog.close().then(() => {
        this.init();
      });
    },
    //#endregion email
    //#region authenticator
    setupAuthenticator() {
      this.$refs.authSetupDialog.open();
    },
    onAuthDismiss(lastStep) {
      // console.log(lastStep);
      if (lastStep == 2 || lastStep == 3) {
        this.init();
      }
    },
    verifyAuthOld() {
      if (this.$store.state.changeAuthenticatorRequireCheck == true) {
        this.$refs.authVerifyDialog.open();
      } else {
        this.$refs.authSetupDialog.open(2);
      }
    },
    onAuthVerifyResult(val) {
      if (val) {
        this.$refs.authVerifyDialog.close().then(() => {
          this.$refs.authSetupDialog.open(2);
        });
      }
    },
    onAuthRemoveResult(val) {
      if (val) {
        this.$refs.removeAuthDialog.close().then(() => {
          this.overlay = true;
          accountService.authenticatorService
            .remove()
            .then((result) => {
              if (result) {
                this.init();
              } else {
                this.$bvModal.msgBoxOk("ไม่สามารถดำเนินการได้", {
                  title: "เกิดข้อผิดพลาด",
                  centered: true,
                });
              }
            })
            .catch((err) => {
              // do nothing
            })
            .finally(() => {
              this.overlay = false;
            });
        });
      }
    },
    deleteAuthenticator() {
      this.$refs.removeAuthDialog.open();
      // this.$bvModal
      //   .msgBoxConfirm(`คุณต้องการที่จะลบการตั้งค่าใช่หรือไม่`, {
      //     title: "โปรดยืนยัน",
      //     centered: true,
      //     okVariant: "danger",
      //     okTitle: "ยืนยัน",
      //     cancelVariant: "light",
      //     cancelTitle: "ยกเลิก",
      //   })
    },
    //#endregion authenticator
    //#region mobile sms
    onMobileSetupDismiss(val) {
      if (val) {
        this.init();
      } else {
        // something error
      }
    },
    onOldPhoneNumberVerifyResult(val) {
      if (val) {
        this.$refs.mobileSetupDialog.open().then((e) => {
          console.log(e);
          e.focus();
        });
      } else {
        this.$bvModal.msgBoxOk(
          "หมายเลขโทรศัพท์ไม่ตรงกัน โปรดระบุใหม่อีกครั้ง",
          {
            title: "แจ้งเตือน",
            centered: true,
          }
        );
      }
    },
    changeMobileNo() {
      this.$refs.oldMobileNoVerifyDialog.open();
    },
    //#endregion mobile sms
  },
};
</script>