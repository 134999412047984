<template>
  <b-modal
    title="ลบการตั้งค่า Authenticator App"
    centered
    v-model="dialog"
    hide-header-close
    ok-title="ยกเลิก"
    @ok="dismiss"
    ok-variant="light"
    no-close-on-backdrop
    ok-only
  >
    <div class="mb-4">
      <b-form-group label="เลือกวิธีในการรับ One Time Password">
        <b-form-select
          v-model="selectedMeleteMethod"
          :options="deleteMethods"
        ></b-form-select>
      </b-form-group>
    </div>
    <template v-if="config != null">
      <template v-if="selectedMeleteMethod == 'email'">
        <div class="bg-white text-center">
          <div class="mt-2 mb-3">
            กรุณาป้อนรหัสความปลอดภัย OTP 6 หลัก ที่ส่งไปยัง
            <div>
              อีเมล:
              <h6 class="d-inline font-weight-bold">{{ config.mail.email }}</h6>
            </div>
          </div>
          <div class="mb-3">
            รหัสอ้างอิง: <strong class="text-info">{{ refNo }}</strong>
          </div>
          <input-series
            ref="inputEmailToken"
            width="360px"
            series-id="inputEmailToken"
            @filled="onEmailTokenFilled"
          ></input-series>
        </div>
      </template>

      <template v-else-if="selectedMeleteMethod == 'mobile'">
        <div class="bg-white text-center">
          <div class="mt-2 mb-3">
            กรุณาป้อนรหัสความปลอดภัย OTP 6 หลัก ที่ส่งไปยัง
            <div>
              หมายเลขโทรศัพท์ที่ลงท้ายด้วย:
              <h6 class="d-inline font-weight-bold">
                {{ config.mobileOtp.last_digits }}
              </h6>
            </div>
          </div>
          <div class="mb-3">
            รหัสอ้างอิง: <strong class="text-info">{{ refNo }}</strong>
          </div>
          <input-series
            ref="inputMobileSMSToken"
            width="360px"
            series-id="inputMobileSMSToken"
            @filled="onSMSTokenFilled"
          ></input-series>
        </div>
      </template>
    </template>

    <div class="mt-4">
      <div class="m-auto" style="width: 360px">
        <template v-if="isCountdown">
          <vue-countdown
            ref="countdown"
            :time="timeValue"
            :auto-start="false"
            @end="resetCountdown"
            @abort="resetCountdown"
            v-slot="{ totalSeconds }"
          >
            <div>
              เหลือเวลา
              <strong>
                {{
                  totalSeconds == 0 ? timeValue / 1000 : totalSeconds
                }}</strong
              >
              วินาที
            </div>
            <div class="mt-2">
              <b-progress
                :variant="progressAutoColor(totalSeconds)"
                :value="totalSeconds == 0 ? timeValue / 1000 : totalSeconds"
                :max="timeValue / 1000"
              ></b-progress>
            </div>
          </vue-countdown>
        </template>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <b-button variant="light" @click="cancel()" :disabled="overlay">
            ยกเลิก
          </b-button>
        </div>
        <div class="flex-shrink-1">
          <b-button
            @click="reqOTP"
            variant="primary"
            :disabled="isCountdown || overlay"
            >ขอรหัส OTP</b-button
          >
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import InputSeries from "../input-series.vue";
import accountService from "../../services/accountService";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  components: {
    InputSeries,
    VueCountdown,
  },
  data: () => ({
    dialog: false,
    step: 1,
    overlay: false,
    selectedMeleteMethod: "email", // email, mobile
    isCountdown: false,
    expiresMinute: 3,
    timeValue: 60 * 1000,
    refNo: "",
  }),
  computed: {
    deleteMethods() {
      let items = [{ text: "อีเมล", value: "email" }];
      if (this.config != null && this.config.mobileOtp.verified === true) {
        items.push({ text: "หมายเลขโทรศัพท์มือถือ", value: "mobile" });
      }
      return items;
    },
  },
  methods: {
    open(step = 1) {
      this.dialog = true;
    },
    dismiss(val = false) {
      this.dialog = false;
      this.overlay = false;
      this.step = 1;
      this.$emit("dismiss", val);
    },
    close() {
      this.dismiss();
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          setTimeout(() => {
            resolve();
          });
        });
      });
    },
    reqOTP() {
      this.overlay = true;
      this.resetCountdown();
      this.refNo = "";
      /** @type {Promise} */
      let promise = null;
      if (this.selectedMeleteMethod == "mobile") {
        promise = accountService.mobile.reqTOTP("", true);
        this.expiresMinute = 1;
      } else {
        promise = accountService.email.reqTOTP();
        this.expiresMinute = 3;
      }

      promise
        .then((result) => {
          // console.log(result);
          // this.timeValue = (60 + result.left) * 1000; // in case plus remaining time
          this.timeValue = this.expiresMinute * 60 * 1000;
          this.refNo = result.refNo;
          // console.log(this.timeValue, this.timeValue / 1000);
          this.isCountdown = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.countdown.start();
            });
          });

          if (this.selectedMeleteMethod == "mobile") {
            this.$refs.inputMobileSMSToken.focusFirst();
          } else {
            this.$refs.inputEmailToken.focusFirst();
          }
        })
        .catch((err) => {
          // do nothing
          this.isCountdown = false;
          this.refNo = "";
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onEmailTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.email
        .verifyTOTP(token)
        .then((result) => {
          if (result == true) {
            this.$emit("result", true);
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          //   console.log(err);
          this.$refs.inputEmailToken.clear();
          this.$emit("result", false);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onSMSTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.mobile
        .verifyTOTP(token)
        .then((result) => {
          if (result == true) {
            // do something
            this.$emit("result", true);
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$refs.inputMobileSMSToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    resetCountdown() {
      this.isCountdown = false;
    },
    progressAutoColor(val) {
      if (val > 40) {
        return "success";
      } else if (val > 20) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
};
</script>