<template>
  <b-modal
    id="verifyMailDialog"
    title="การยืนยันอีเมลโดย OTP"
    centered
    v-model="dialog"
    hide-header-close
    ok-title="ยกเลิก"
    @ok="dismiss"
    ok-variant="light"
    no-close-on-backdrop
    ok-only
  >
    <div class="bg-white text-center">
      <p>
        เราได้ส่งรหัสความปลอดภัย One Time Password (OTP) ไปยังอีเมล
        {{ email }} โปรดตรวจสอบและระบุรหัสในช่องด้านล่าง
      </p>

      <div class="mt-2 mb-3">กรุณาป้อนรหัสความปลอดภัย 6 หลัก</div>
      <div class="mb-3">
        รหัสอ้างอิง: <strong class="text-info">{{ refNo }}</strong>
      </div>
      <input-series
        ref="inputEmailToken"
        width="360px"
        series-id="inputEmailToken"
        @filled="onTokenFilled"
      ></input-series>

      <div class="mt-4 mb-4">
        <div class="m-auto" style="width: 360px">
          <template v-if="isCountdown">
            <vue-countdown
              ref="countdown"
              :time="timeValue"
              :auto-start="false"
              @end="resetCountdown"
              @abort="resetCountdown"
              v-slot="{ totalSeconds }"
            >
              <div>
                เหลือเวลา
                <strong>
                  {{
                    totalSeconds == 0 ? timeValue / 1000 : totalSeconds
                  }}</strong
                >
                วินาที
              </div>
              <div class="mt-2">
                <b-progress
                  :variant="progressAutoColor(totalSeconds)"
                  :value="totalSeconds == 0 ? timeValue / 1000 : totalSeconds"
                  :max="timeValue / 1000"
                ></b-progress>
              </div>
            </vue-countdown>
          </template>
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <b-button variant="light" @click="cancel()" :disabled="overlay">
            ยกเลิก
          </b-button>
        </div>
        <div class="flex-shrink-1">
          <b-button
            @click="reqOTP"
            variant="primary"
            :disabled="isCountdown || overlay"
            >ขอรหัส OTP</b-button
          >
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import InputSeries from "../input-series.vue";
import accountService from "../../services/accountService";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  components: {
    InputSeries,
    VueCountdown,
  },
  data: () => ({
    dialog: false,
    overlay: false,
    email: null,
    isCountdown: false,
    timeValue: 0,
    refNo: "",
    expiresMinute: 3,
  }),
  methods: {
    open() {
      this.refNo = "";
      this.dialog = true;
      // this.$nextTick(() => {
      //   this.reqOTP();
      // });
    },
    dismiss() {
      this.dialog = false;
      this.$emit("dismiss");
    },
    close() {
      this.dismiss();
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          setTimeout(() => {
            resolve();
          });
        });
      });
    },
    reqOTP() {
      this.overlay = true;
      this.resetCountdown();
      this.refNo = "";
      accountService.email
        .reqTOTP()
        .then((result) => {
          // console.log(result);
          // this.timeValue = (60 + result.left) * 1000; // in case plus remaining time
          this.timeValue = this.expiresMinute * 60 * 1000;
          this.refNo = result.refNo;
          // console.log(this.timeValue, this.timeValue / 1000);
          this.isCountdown = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.countdown.start();
            });
          });
          this.$refs.inputEmailToken.focusFirst();
        })
        .catch((err) => {
          // do nothing
          this.isCountdown = false;
          this.refNo = "";
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.email
        .verifyTOTP(token, true)
        .then((result) => {
          if (result == true) {
            this.$emit("result", true);
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          //   console.log(err);
          this.$refs.inputEmailToken.clear();
          this.$emit("result", false);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    resetCountdown() {
      this.isCountdown = false;
    },
    getSeconds(props) {},
    progressAutoColor(val) {
      if (val > 40) {
        return "success";
      } else if (val > 20) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
};
</script>