<template>
  <b-modal
    id="authSetupDialog"
    title="การตั้งค่า Authenticator App"
    centered
    v-model="dialog"
    @cancel="dismiss"
    hide-header-close
    no-close-on-backdrop
  >
    <div class="bg-white">
      <div class="bs-stepper" id="authSetupDialog_stepper">
        <div class="bs-stepper-header" role="tablist">
          <!-- your steps here -->
          <div class="step" data-target="#install-app">
            <button
              type="button"
              class="step-trigger"
              role="tab"
              aria-controls="install-app"
              id="install-app-trigger"
            >
              <span class="bs-stepper-circle">1</span>
              <span class="bs-stepper-label">ติดตั้งแอปฯ</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step" data-target="#auth-setup-code">
            <button
              type="button"
              class="step-trigger"
              role="tab"
              aria-controls="auth-setup-code"
              id="auth-setup-code-trigger"
            >
              <span class="bs-stepper-circle">2</span>
              <span class="bs-stepper-label">ตั้งค่า</span>
            </button>
          </div>
          <div class="line"></div>
          <div class="step" data-target="#auth-setup-finish">
            <button
              type="button"
              class="step-trigger"
              role="tab"
              aria-controls="auth-setup-finish"
              id="auth-setup-finish-trigger"
            >
              <span class="bs-stepper-circle">3</span>
              <span class="bs-stepper-label">สำเร็จ</span>
            </button>
          </div>
        </div>
        <div class="bs-stepper-content pt-1">
          <!-- your steps content here -->
          <div
            id="install-app"
            class="content"
            role="tabpanel"
            aria-labelledby="install-app-trigger"
          >
            กรุณาติดตั้งแอปฯ Google Authenticator ในมือถือของท่าน
            โดยเลือกไอคอนด้านล่างนี้ หรือสแกนจาก QR Code
            ด้านล่างของแต่ละแพลตฟอร์ม <br />
            <p class="mt-2 mb-0">
              * หมายเหตุ - ท่านสามารถใช้แอปฯ Microsoft Authenticator ได้เช่นกัน
            </p>
            <div>
              <div class="d-flex justify-content-around">
                <div class="text-center">
                  <div class="mt-3 mb-0 text-center font-weight-bold">
                    Android
                  </div>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=th&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    ><img
                      style="max-width: 180px"
                      alt="Get it on Google Play"
                      src="~@/assets/imgs/google-play-badge.png"
                  /></a>
                  <template v-if="showQRLinkApp">
                    <div class="mt-3">
                      <img
                        style="max-width: 150px"
                        src="~@/assets/imgs/qrcode-app-authenticator.png"
                        alt=""
                      />
                    </div>
                  </template>
                </div>
                <div class="text-center">
                  <div class="mt-3 mb-0 text-center font-weight-bold">iOS</div>
                  <a
                    target="_blank"
                    style="
                      display: inline-block;
                      margin-top: 10px;
                      border-radius: 13px;
                      width: 150px;
                    "
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
                    ><img
                      src="~@/assets/imgs/appstore-auth-badge.svg"
                      alt="Download on the App Store"
                      style="border-radius: 13px; width: 150px"
                  /></a>
                  <template v-if="showQRLinkApp">
                    <div class="mt-4">
                      <img
                        style="max-width: 150px"
                        src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/45355390113d376a941d56b19ff9c8e2/a9cc04bd997f2b64d1549116ebeb42a8.png"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            id="auth-setup-code"
            class="content"
            role="tabpanel"
            aria-labelledby="auth-setup-code-trigger"
          >
            <p>
              กรุณาเปิดแอพฯ Authenticator เพื่อสแกน QR Code ด้านล่างนี้ และจะมี
              รายการของ NerdOf เพิ่มขึ้นมา โปรดใช้รหัส 6
              หลักป้อนในช่องเพื่อยืนยันต่อไป
            </p>
            <div class="text-center">
              <div
                style="height: 200px; width: 200px"
                class="d-flex align-items-center justify-content-center m-auto"
              >
                <template v-if="qrCodeBlobUrl != null">
                  <img
                    style="height: 200px; width: 200px"
                    ref="authQrCode"
                    :src="qrCodeBlobUrl"
                    @load="authQrCodeLoaded"
                  />
                </template>
                <template v-else>
                  <div class="text-center">
                    <div>
                      <b-spinner
                        variant="primary"
                        type="grow"
                        label="Spinning"
                      ></b-spinner>
                    </div>
                    <div class="mt-2"><em>กำลังดำเนินการ</em></div>
                  </div>
                </template>
              </div>

              <div class="">
                <template v-if="shareSecret != '' && shareSecret != null">
                  <div class="mt-0 mb-4" v-if="showShareSecret">
                    <span class="body-2">ใส่รหัสเองที่แอปฯ</span>
                    <div
                      class="alert alert-light"
                      role="alert"
                      style="word-break: break-all"
                    >
                      {{ shareSecret }}
                    </div>
                  </div>

                  <div class="mt-2 mb-2">
                    กรุณาป้อนรหัส 6 หลัก ที่ปรากฎในแอปฯ
                  </div>
                  <input-series
                    ref="authenticatorToken"
                    width="360px"
                    series-id="authenticator-token"
                    @filled="onTokenFilled"
                  ></input-series>
                </template>
              </div>
            </div>
          </div>
          <div
            id="auth-setup-finish"
            class="content"
            role="tabpanel"
            aria-labelledby="auth-setup-finish-trigger"
          >
            <div class="font-weight-bold mb-2 text-success">
              <i class="uil uil-check"></i>
              ดำเนินการสำเร็จ
            </div>
            <p>ท่านได้ทำการเพิ่ม Authenticator App สำเร็จเรียบร้อยแล้ว</p>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <template v-if="step != 3">
            <b-button variant="light" @click="cancel()" :disabled="overlay">
              ยกเลิก
            </b-button>
          </template>
        </div>
        <div class="flex-shrink-1">
          <template v-if="step == 1">
            <b-button variant="primary" @click="nextStep()"> ถัดไป </b-button>
          </template>
          <template v-else-if="step == 2">
            <b-button
              variant="light"
              @click="prevStep"
              class="ml-2"
              :disabled="overlay"
            >
              ก่อนหน้า
            </b-button>
          </template>
          <template v-else>
            <b-button variant="success" @click="dismiss" class="ml-2">
              ปิด
            </b-button>
          </template>
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import Stepper from "bs-stepper";
import InputSeries from "../input-series.vue";
import accountService from "../../services/accountService";
export default {
  components: {
    InputSeries,
  },
  data: () => ({
    dialog: false,
    step: 1,
    stepper: null,
    showQRLinkApp: false,
    shareSecret: "",
    showShareSecret: false,
    isTokenCorrected: false,
    overlay: false,
    qrCodeBlobUrl: null,
  }),
  watch: {
    step(val) {
      if (val == 1) {
        this.qrCodeBlobUrl = null;
      } else if (val == 2) {
        this.getShareSecret();
      }
    },
    // dialog(val) {
    //   if (val == false) {
    //     this.checkClose();
    //   }
    // },
  },
  mounted() {},
  methods: {
    open(step = 1) {
      this.dialog = true;
      this.$nextTick(() => {
        let el = document.querySelector("#authSetupDialog_stepper");
        this.stepper = new Stepper(el);

        el.addEventListener("shown.bs-stepper", (event) => {
          this.step = event.detail.indexStep + 1;
        });

        if (step != 1) {
          this.stepper.to(step);
        }
      });
    },
    prevStep() {
      this.stepper.previous();
    },
    nextStep() {
      this.stepper.next();
    },
    toStep(step = 0) {
      this.stepper.to(step);
    },
    dismiss(val = false) {
      let lastStep = this.step;
      this.dialog = false;
      this.stepper.destroy();
      this.overlay = false;
      this.qrCodeBlobUrl = null;
      this.step = 1;
      this.$emit("dismiss", lastStep);
    },
    getShareSecret() {
      accountService.authenticatorService
        .getShareSecret()
        .then((code) => {
          this.shareSecret = code;
          return accountService.authenticatorService.qrcode();
        })
        .then((blobUrl) => {
          this.qrCodeBlobUrl = blobUrl;
          this.$refs.authenticatorToken.focusFirst();
        });
    },
    authQrCodeLoaded() {
      if (this.qrCodeBlobUrl) URL.revokeObjectURL(this.qrCodeBlobUrl);
    },
    onTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.authenticatorService
        .verify(token)
        .then((result) => {
          if (result == true) {
            this.isTokenCorrected = true;
            this.nextStep();
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.isTokenCorrected = false;
          this.$refs.authenticatorToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    checkClose() {
      if (this.step == 3 || this.step == 2) {
        // because step 2 generate new share secret and verified = false
        this.$emit("statusChange");
      }
      // other step do nothimng
    },
  },
};
</script>