<template>
  <b-modal
    title="ตรวจสอบหมายเลขโทรศัพท์เดิม"
    centered
    v-model="dialog"
    hide-header-close
    ok-title="ยกเลิก"
    @ok="dismiss"
    ok-variant="light"
    no-close-on-backdrop
    ok-only
  >
    <div class="bg-white text-center">
      <!-- <p>กรุณาระบุหมายเลขโทรศัพท์มือถือเดิม</p> -->
      <div>
        <mobile-no-input
          ref="mobileInputVerifyOldMobileNo"
          @change="onMobileChange"
          placeholder="เช่น 080XXXXXXX"
        />
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <b-button variant="light" @click="cancel()" :disabled="overlay">
            ยกเลิก
          </b-button>
        </div>
        <div class="flex-shrink-1">
          <b-button
            @click="verify"
            variant="primary"
            :disabled="mobileNo == '' || mobileNo == null || overlay"
            >ตรวจสอบ</b-button
          >
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import MobileNoInput from "../mobileNoInput.vue";
import accountService from "../../services/accountService";
export default {
  components: {
    MobileNoInput,
  },
  data: () => ({
    dialog: false,
    overlay: false,
    mobileNo: null,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
    dismiss() {
      this.overlay = false;
      this.mobileNo = null;
      this.dialog = false;
    },
    close() {
      this.dismiss();
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          setTimeout(() => {
            resolve();
          });
        });
      });
    },
    onMobileChange(val) {
      this.mobileNo = val;
    },
    verify() {
      if (this.$refs.mobileInputVerifyOldMobileNo.validate() == false) {
        return;
      }

      this.overlay = true;
      accountService.mobile
        .verifyOldMobileNo(this.mobileNo)
        .then((result) => {
          if (result == true) {
            this.dismiss();
          }
          this.$emit("result", result);
        })
        .catch((err) => {
          // do nothing
          this.$bvModal.msgBoxOk(
            "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อผู้ดูแลระบบ",
            {
              title: "เกิดข้อผิดพลาด",
              centered: true,
            }
          );
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
};
</script>